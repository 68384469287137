<template>
  <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.8671 8.17261L16.0447 0.349731C15.931 0.235718 15.7792 0.172852 15.6179 0.172852H15.6176C15.4565 0.172852 15.3052 0.235718 15.1911 0.349365L7.36749 8.1731C7.25372 8.28711 7.19092 8.43884 7.19092 8.59985V10.4799V11.951V12.4478C7.19092 12.6927 7.33746 12.9119 7.5636 13.0054C7.78979 13.0988 8.0481 13.048 8.22137 12.8745L9.1521 11.9434L10.6238 10.4722L13.6026 7.49365C13.6215 7.47485 13.6534 7.48804 13.6534 7.51453V10.4722V11.9434V19.5221C13.6534 19.8715 13.9369 20.1549 14.2864 20.1549H16.9482C17.2977 20.1549 17.5815 19.8715 17.5815 19.5217V11.9434V10.4722V7.51453C17.5815 7.48804 17.6136 7.47485 17.6323 7.49365L20.6112 10.4722L22.0824 11.9434L23.0136 12.8745C23.187 13.048 23.4452 13.0988 23.6713 13.0054C23.8976 12.9119 24.0441 12.6927 24.0441 12.4478V11.951V10.4799V8.59985C24.0441 8.43884 23.9812 8.28711 23.8671 8.17261Z" fill="var(--clr-primary)"/>
    <path d="M29.9893 29.3973H1.24536C0.582092 29.3973 0.0444336 28.8594 0.0444336 28.1962V20.1021C0.0444336 19.5078 0.444336 18.9537 1.03003 18.852C1.78827 18.7203 2.44641 19.3006 2.44641 20.0341V26.9655C2.44641 26.9819 2.45959 26.9952 2.47595 26.9952H28.7585C28.775 26.9952 28.7882 26.9819 28.7882 26.9655V20.1021C28.7882 19.5078 29.1886 18.9537 29.7743 18.852C30.532 18.7203 31.1902 19.3006 31.1902 20.0341V28.1962C31.1902 28.8594 30.6525 29.3973 29.9893 29.3973Z" fill="var(--clr-primary)"/>
  </svg>
</template>

<script>
export default {
  name: "MainShareIcon"
}
</script>

<style scoped>

</style>
<template>
  <div class="widget-salesman-container">
    <div style="max-width: calc(100%)" @mousedown.left="onMouseDown">
      <horizontal-scroll class="horizontal-scroll" ref="time" @scroll="onScroll" style="cursor: move;user-select: none;">
        <div class="outer" style="display: inline-flex; gap: 8px">
          <div class="inner-content" v-for="time in times" :key="time.value" @click="setTime(time.value)">
            <div style="display: flex; flex-direction: column;">
              <book-time :time="time"></book-time>
            </div>
          </div>
        </div>
      </horizontal-scroll>
    </div>
  </div>
</template>


<script>
import store from "../../store";
import HorizontalScroll from 'vue-horizontal-scroll'
import BookTime from "./BookTime";

export default {
  name: "BookTimeContainer",
  components: {BookTime, HorizontalScroll},
  data(){
    return{
      left: 0,
      originX: 0,
      originLeft: 0,
    }
  },
  methods: {
    setTime(time){
      store.dispatch('setTime',time);
    },
    onScroll({left}) {

      this.left = left
    },

    onMouseDown(e) {
      this.originX = e.pageX
      this.originLeft = this.left
      window.addEventListener("mouseup", this.onMouseUp);
      window.addEventListener("mousemove", this.onMouseMove);
    },
    onMouseUp() {
      window.removeEventListener("mouseup", this.onMouseUp);
      window.removeEventListener("mousemove", this.onMouseMove);
    },
    onMouseMove(e) {
      const offset = e.pageX - this.originX
      this.left = this.originLeft - offset
      this.$refs.time.$el.scrollTo(this.left, 'auto')
    },

  },
  computed:{
    times(){
      return store.getters.times;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="widget-salesman-container">
    <book-salesman :person="{name: i18n.global.t('anyone'), id:'all'}"></book-salesman>
    <separator class="widget-salesman--separator"></separator>
    <div style="max-width: calc(100% - 75px)" @mousedown.left="onMouseDown">
      <horizontal-scroll class="horizontal-scroll" ref="person" @scroll="onScroll" style="cursor: move;user-select: none;">
        <div class="outer" style="display: inline-flex; gap: 40px; padding-left:20px">
          <div class="inner-content" v-for="person in persons" :key="person.id">
            <div style="display: flex; flex-direction: column;">
              <book-salesman :person="person"></book-salesman>
            </div>
          </div>
        </div>
      </horizontal-scroll>
    </div>
  </div>
</template>


<script>
import store from "../../store";
import HorizontalScroll from 'vue-horizontal-scroll'
import BookSalesman from "../book/BookSalesman";
import Separator from "../svg/Separator";
import i18n from "../../locale";

export default {
  name: "BookSalesmanContainer",
  components: {Separator, BookSalesman, HorizontalScroll},
  data(){
    return{
      left: 0,
      originX: 0,
      originLeft: 0,
      i18n:i18n
    }
  },
  methods: {
    setUser(user){
      store.dispatch('setUser',user);
    },
    noUser(){
      store.dispatch('setUser',999);
    },
    onScroll({left}) {

      this.left = left
    },
    onMouseDown(e) {
      this.originX = e.pageX
      this.originLeft = this.left
      window.addEventListener("mouseup", this.onMouseUp);
      window.addEventListener("mousemove", this.onMouseMove);
    },
    onMouseUp() {
      window.removeEventListener("mouseup", this.onMouseUp);
      window.removeEventListener("mousemove", this.onMouseMove);
    },
    onMouseMove(e) {
      const offset = e.pageX - this.originX
      this.left = this.originLeft - offset
      this.$refs.person.$el.scrollTo(this.left, 'auto')
    },
  },
  computed:{
    persons(){
      return store.getters.persons
    }
  }
}
</script>

<style scoped>

</style>
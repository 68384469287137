<template>
  <div class="financing-container">
    <div class="financing-wrapper">
      <div class="financing-photo">
        <img :src="audi">
      </div>
      <div class="financing-content-wrapper">
        <div class="financing-content">
          <div>
            <back-component></back-component>
          </div>
          <div><img :src="bank"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackComponent from "../components/BackComponent";
export default {
  name: "FinancingPage",
  components: {BackComponent},
  data() {
    return {
      audi: require('../assets/AudiA7.png'),
      bank: require('../assets/financing.svg'),
    }
  },
}
</script>
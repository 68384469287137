<template>
  <svg width="23" height="41" viewBox="0 0 23 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.87061 32.2355L7.87927 32.2421L7.87061 32.2355Z" fill="var(--clr-primary)"/>
    <path d="M16.5477 20.933V37.64C16.4097 37.4689 16.2678 37.2928 16.1258 37.1171L14.429 35.0194C14.2928 34.8517 14.0945 34.7706 13.8713 34.7562C13.655 34.7577 13.4516 34.8575 13.3176 35.028C12.5959 35.9457 11.8235 36.9208 11.2052 37.6942V33.0568C10.9625 32.7567 10.6752 32.3956 10.326 31.9572C10.1415 31.7255 9.95801 31.4948 9.7832 31.2738V39.6664C9.7832 39.9691 9.9762 40.2406 10.2624 40.3402C10.3533 40.371 10.4282 40.3973 10.5083 40.3973C10.8792 40.3973 11.3471 39.8324 13.8849 36.6075C15.0638 38.0641 16.4705 39.8055 16.6659 40.0582C16.692 40.0978 16.722 40.1349 16.7562 40.1691C17.0237 40.4357 17.4558 40.4493 17.7369 40.1964C17.9725 39.9845 18.0367 39.7958 17.9697 39.5697V20.0355C17.5222 20.371 17.0457 20.6703 16.5477 20.933Z" fill="var(--clr-primary)"/>
    <path d="M7.82922 32.2043L7.82861 32.2041C7.85248 32.2217 7.86273 32.2295 7.87122 32.2351C7.86322 32.2295 7.85248 32.2217 7.82922 32.2043Z" fill="var(--clr-primary)"/>
    <path d="M12.5933 22.1199C12.3589 22.1423 12.1215 22.1553 11.8824 22.1628V31.6282C11.6729 31.3667 11.4423 31.0769 11.2049 30.7783C10.7472 30.2017 10.2565 29.5818 9.78304 28.9797L9.76992 28.9631C9.63533 28.7917 9.42916 28.6919 9.21169 28.6919H9.21114C8.99355 28.6919 8.78743 28.7913 8.65291 28.9626C8.04006 29.739 7.21559 30.7791 6.53981 31.6235V20.9329C6.29677 20.8044 6.05989 20.6665 5.82875 20.5208C5.58522 20.3674 5.34731 20.2068 5.11782 20.0344V33.5891C5.11269 33.8474 5.24904 34.0977 5.48525 34.2285C6.02242 34.5254 6.26839 34.2195 6.92824 33.408C7.19228 33.084 7.5262 32.6675 7.87093 32.2356C7.86238 32.2297 7.85219 32.2222 7.82839 32.2041L7.82887 32.2048L7.82839 32.2041C7.84249 32.2144 7.85726 32.2246 7.87148 32.2346C7.94533 32.1421 8.01912 32.0498 8.09297 31.9568C8.49782 31.4497 8.90108 30.9412 9.21047 30.5498C9.37813 30.7625 9.5746 31.0107 9.78304 31.2737C9.9579 31.4949 10.1414 31.7253 10.3259 31.957C10.6751 32.3962 10.9624 32.7573 11.2049 33.0571C12.0261 34.0725 12.3146 34.3621 12.5684 34.3621C12.6775 34.3621 12.7814 34.3088 12.9193 34.2375C13.1636 34.1116 13.3084 33.8579 13.3044 33.592V22.0254C13.0698 22.064 12.8331 22.0964 12.5933 22.1199Z" fill="var(--clr-primary)"/>
    <path d="M7.87439 32.2371C7.88293 32.2437 7.88574 32.2461 7.87958 32.2415C7.88574 32.2463 7.88293 32.2437 7.87439 32.2371L7.87158 32.2346L7.87439 32.2371Z" fill="var(--clr-primary)"/>
    <path d="M7.87152 32.2346L7.87109 32.2354L7.87952 32.2415C7.8858 32.2461 7.88287 32.2437 7.87445 32.2371L7.87152 32.2346Z" fill="var(--clr-primary)"/>
    <path d="M7.82922 32.2043C7.84338 32.2148 7.8576 32.2244 7.87177 32.2349C7.8576 32.2244 7.84277 32.2144 7.82861 32.2041L7.82922 32.2043Z" fill="var(--clr-primary)"/>
    <path d="M7.87164 32.2347C7.85748 32.2242 7.84332 32.2147 7.8291 32.2045C7.85236 32.2218 7.8631 32.2296 7.87115 32.2352L7.87164 32.2347Z" fill="var(--clr-primary)"/>
    <path d="M11.5446 22.8823C5.23602 22.8823 0.103516 17.7498 0.103516 11.4412C0.103516 5.13208 5.23602 0 11.5446 0C17.8532 0 22.9857 5.13208 22.9857 11.4412C22.9857 17.7498 17.8532 22.8823 11.5446 22.8823ZM11.5446 1.42188C6.01971 1.42188 1.52545 5.9165 1.52545 11.4412C1.52545 16.9656 6.01971 21.4604 11.5446 21.4604C17.069 21.4604 21.5637 16.9656 21.5637 11.4412C21.5637 5.9165 17.069 1.42188 11.5446 1.42188Z" fill="var(--clr-primary)"/>
    <path d="M11.5446 18.9164C7.42236 18.9164 4.06836 15.5634 4.06836 11.441C4.06836 7.31897 7.42236 3.96545 11.5446 3.96545C15.6668 3.96545 19.0203 7.31897 19.0203 11.441C19.0203 15.5634 15.6668 18.9164 11.5446 18.9164ZM11.5446 5.38708C8.2066 5.38708 5.49097 8.10315 5.49097 11.441C5.49097 14.7787 8.2066 17.4948 11.5446 17.4948C14.8826 17.4948 17.5983 14.7787 17.5983 11.441C17.5983 8.10315 14.8826 5.38708 11.5446 5.38708Z" fill="var(--clr-primary)"/>
  </svg>

</template>

<script>
export default {
  name: "MainCertIcon"
}
</script>

<style scoped>

</style>
import Vue from 'vue';
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        active_page: "main",
        image: true,
        mobile: false,
        id_offer: null,
        logo: false,
        company_name: '[Your COMPANY Name]',
        car: {
            name: "Marka Model",
            model: "Wersja",
            year: 'Rok',
            milage: 'Przebieg',
            fuel_type: 'Paliwo',
            gearbox: 'Skrzynia',
            gross_price: 'Cena b.',
            net_price: 'Cena net',
            installment_price: '1500 zł/mo',
            imageUrl: null,
            player360Url: null,
        },
        phone: "+48 000 000 000",
        book: {
            date: null,
            person: 0,
            time: null,
            type: 'TEST_DRIVE',
            notes: null,
        },
        times: [
            {
                label: '8:00 AM',
                value: '08:00',
            },
            {
                label: '8:30 AM',
                value: '08:30',
            },
            {
                label: '9:00 AM',
                value: '09:00',
            },
            {
                label: '9:30 AM',
                value: '09:30',
            },
            {
                label: '10:00 AM',
                value: '10:00',
            },
            {
                label: '10:30 AM',
                value: '10:30',
            },
            {
                label: '11:00 AM',
                value: '11:00',
            },
            {
                label: '11:30 AM',
                value: '11:30',
            },
            {
                label: '12:00 AM',
                value: '12:00',
            },
            {
                label: '12:30 AM',
                value: '12:30',
            },
            {
                label: '1:00 PM',
                value: '13:00',
            },
            {
                label: '1:30 PM',
                value: '13:30',
            },
            {
                label: '2:00 PM',
                value: '14:00',
            },
            {
                label: '2:30 PM',
                value: '14:30',
            },
            {
                label: '3:00 PM',
                value: '15:00',
            },
            {
                label: '3:30 PM',
                value: '15:30',
            },
            {
                label: '4:00 PM',
                value: '16:00',
            },
            {
                label: '4:30 PM',
                value: '16:30',
            },
            {
                label: '5:00 PM',
                value: '17:00',
            },
            {
                label: '5:30 PM',
                value: '17:30',
            },
            {
                label: '6:00 PM',
                value: '18:00',
            },
        ],
        persons: [
            {
                id: "test-seller-1",
                name: 'Tomasz',
            },
        ],
        offer: {
            url: 'https://vc360-prod-euw-appservice-announcementsapi.azurewebsites.net/api/v1/Announcements/',
            id: null,
            portal: null,
            data: {},
        },
        client: {
            name: '',
            surname: '',
            phone: '',
            email: '',
        }
    },
    mutations: {
        SET_LOGO(state, logo) {
            state.logo = logo;
        },
        SET_COMPANY_NAME(state, company_name) {
            state.company_name = company_name;
        },
        SET_PERSON(state, person) {
            state.book.person = person;
        },
        SET_DATE(state, date) {
            state.book.date = date;
        },
        SET_TYPE(state,type){
           state.book.type = type;
        },
        SET_TIME(state, time) {
            state.book.time = time;
        },
        SET_NOTES(state,notes){
            state.book.notes = notes;
        },
        SET_VIEW(state, page) {
            state.active_page = page;
        },
        SET_PHONE(state, phone) {
            state.phone = phone;
        },
        SET_CAR(state, car) {
            state.car = car;
        },
        SET_IMAGE(state, image) {
            state.image = image;
        },
        SET_MOBILE(state, mobile) {
            state.mobile = mobile;
        },
        SET_CLIENT(state, client) {
            state.client = client;
        },
        SET_ID_OFFER(state, id_offer){
          state.id_offer = id_offer
        }
    },
    actions: {
        count({commit, state}) {
            let counter = state.counter;
            counter++;
            commit('SET_COUNTER', counter)
        },

        setLogo({commit},logo){
          commit('SET_LOGO',logo)
        },
        setCompanyName({commit},company_name){
            commit('SET_COMPANY_NAME',company_name)
        },
        setUser({commit}, person) {
            commit('SET_PERSON', person)
        },
        setDate({commit}, date) {
            commit('SET_DATE', date)
        },
        setTime({commit}, time) {
            commit('SET_TIME', time)
        },
        setType({commit}, type) {
            commit('SET_TYPE', type)
        },

        setNotes({commit},notes){
            commit('SET_NOTES',notes);
        },
        setPage({commit}, page) {
            commit('SET_VIEW', page)
        },

        setIsImage({commit}, image) {
            commit('SET_IMAGE', image)
        },
        setIsMobile({commit}, mobile) {
            commit('SET_MOBILE', mobile)
        },

        setOffer({commit, state}, offer) {
            if (offer.id && offer.portal) {
                commit('SET_ID_OFFER',offer.id)
                Vue.axios.get(`${state.offer.url}${offer.portal}/${offer.id}/AnnouncementsWithAllImages`).then((response) => {
                    let data = response.data;

                    let properties_data = {};

                    data.basicData.forEach((item) => {
                        let group_label = item.group ? item.group : 'Default'
                        if (!properties_data[group_label]){
                            properties_data[group_label] = {};
                        }
                        let group = properties_data[group_label];
                        group[item.key] = item.value;
                    })


                    commit('SET_PHONE', data.phone)
                    let car = {};
                    car.name = `${data?.mark} ${data?.model}`;
                    car.model = data?.type.replace(data?.model, '');
                    car.gearbox = data?.transmission.replace('skrzynia biegów', '');
                    car.year = data?.manufacturingYear;
                    car.milage = data?.mileage;
                    car.fuel_type = data?.fuel;
                    car.gross_price = data?.price;
                    car.net_price = parseInt(data?.price * 100 / 123);
                    car.installment_price = '1500 zł/mo';
                    car.imageUrl = data?.imageUrl;
                    car.player360Url = data?.player360Url;

                    car.form_mark = data?.mark;
                    car.form_model = data?.model;
                    car.form_photo = data?.imageUrl;
                    car.form_registration_number = properties_data?.DetailsBodyFeatures?.RegNo;
                    car.form_vin_number = properties_data?.DetailsBodyFeatures?.VIN;


                    commit('SET_CAR', car);
                })
            }
        },

        sendBook({commit,state}) {
            let date = new Date(state.book.date);
            if (state.book.type == "INQUIRY"){
                let today = new Date();
                date = new Date(today);
                date.setDate(today.getDate() + 1);
                commit('SET_TIME',"08:00");
            }
            let data = JSON.stringify(
                {
                    "seller":
                        {
                            "seller_id": "test-seller-1"
                        },
                    "reservation_info":
                        {
                            "start_time": state.book.time,
                            "date": `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`,
                            "offer_link": `https://ecommerce.vc360.app/offer/${state.id_offer}`
                        },
                    "client":
                        {
                            "name": state.client.name,
                            "lastname": state.client.surname,
                            "phone": state.client.phone,
                            "email": state.client.email,
                            "language": "pl"
                        },
                    "type": state.book.type,
                    "notes": state.book.notes ?? "",
                    "car_details": {
                        "brand_name": state.car.form_mark,
                        "model_name": state.car.form_model,
                        "photo_link": state.car.form_photo,
                        "registration_number": state.car.form_registration_number,
                        "vin_number": state.car.form_vin_number
                    }
                });


            var config = {
                method: 'post',
                url: 'https://api.crm.vc360.app/api/reservation/request/without',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            Vue.axios(config)
                .then(function (response) {
                    if (response.status == 200){
                        console.log(response.status);
                    }
                    commit('SET_VIEW','thank_you')
                })
                .catch(function (error) {
                    commit('SET_VIEW','book');
                    console.log(error);
                });
        },

        setClient({commit}, client) {
            commit('SET_CLIENT', client)
        }
    },
    getters: {
        active_page: state => state.active_page,
        book: state => state.book,
        times: state => state.times,
        persons: state => state.persons,
        car: state => state.car,
        phone: state => state.phone,
        is_image: state => state.image,
        client: state => state.client,
        type: state => state.book.type,
        logo: state => state.logo,
        company_name: state => state.company_name,
    }
});

<template>
  <div class="form-container" style="width: 375px;">
    <back-component page="book"></back-component>
    <div class="form-wrapper">
      <div class="thank_you" style="height: 169px; display: flex; justify-content: center;">
        <Logo></Logo>
      </div>
      <div style="padding: 0 16px;"><p style="font-size: 18px;color: var(--clr-primary);padding-left: 14px;padding-bottom: 5px;">{{ i18n.global.t('feel_form') }}</p>
        <input type="text" :placeholder="i18n.global.t('customer.name')" class="form-input" :class="this.name.length > 1 ? 'valid' : ''" v-model="name" required minlength="2" maxlength="50">
      </div>
      <div style="padding: 0 16px;">
        <input type="text" :placeholder="i18n.global.t('customer.surname')" class="form-input" :class="this.surname.length > 1 ? 'valid' : ''" v-model="surname" required minlength="2" maxlength="50">
      </div>
      <div style="padding: 0 16px;">
        <input type="email" :placeholder="i18n.global.t('customer.email')" class="form-input" :class="this.validateEmail(this.email) ? 'valid' : ''" v-model="email" required></div>
      <div style="padding: 0 16px;">
        <input type="number" :placeholder="i18n.global.t('customer.phone')" class="form-input" :class="this.validatePhone(this.phone) ? 'valid' : ''" v-model="phone" pattern="[0-9]{9}" required></div>
      <div style="display: flex; flex-direction: row; gap:10px; padding: 0 16px;">
        <input type="checkbox" name="agreement" id="agreement" v-model="agreement">
        <label for="agreement" style="font-size: 10px">{{i18n.global.t('agreement')}} <a href="https://virtualcar360.com/polityka-prywatnosci/" id="privacy_policy" target="_blank">{{i18n.global.t('privacy_policy')}}</a></label>
      </div>
      <div style="padding: 0 16px;">
        <button v-if="validate" class="widget-book-submit" @click="send">{{ i18n.global.t('send') }}</button>
        <button v-else class="widget-book-submit is-disabled">{{ i18n.global.t('send') }}</button>
      </div>
    </div>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import store from "@/store";
import BackComponent from "../components/BackComponent";
import i18n from "../locale";
import FooterBlock from "../components/Footer";
import Logo from "../components/svg/Logo";

export default {
  name: "FormPage",
  components: {Logo, FooterBlock, BackComponent},
  data() {
    return {
      name: '',
      surname: '',
      phone: null,
      email: '',
      agreement: false,
      client: {
        name: '',
        surname: '',
        email: '',
        phone: null,
      },
      i18n: i18n
    }
  },
  methods: {
    setClient() {
      this.client.name = this.name;
      this.client.surname = this.surname;
      this.client.email = this.email;
      this.client.phone = this.phone;
      store.dispatch('setClient', this.client);
    },
    validateEmail(inputText) {
      if(!inputText){
        return false;
      }
      let mailFormat = /\S+@\S+\.\S+/;
      if (inputText.match(mailFormat)) {
        return true;
      } else {
        return false;
      }
    },
    validatePhone(inputText) {
      if(!inputText){
        return false;
      }
      let phoneFormat = /^[0-9+]{8,13}/;
      if (inputText.match(phoneFormat)) {
        return true;
      } else {
        return false;
      }
    },
    send() {
      this.setClient();
      store.dispatch('sendBook');
    },

  },
  computed: {
    validate() {
      if (this.name.length > 1 && this.surname.length > 1 && this.validateEmail(this.email) && this.validatePhone(this.phone) && this.agreement) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53174 9.97239C13.5208 13.9604 14.4258 9.34672 16.9656 11.8848C19.4143 14.3328 20.8216 14.8232 17.7192 17.9247C17.3306 18.237 14.8616 21.9943 6.1846 13.3197C-2.49348 4.644 1.26158 2.17244 1.57397 1.78395C4.68387 -1.32615 5.16586 0.0893829 7.61449 2.53733C10.1544 5.0765 5.54266 5.98441 9.53174 9.97239Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "MainCallIcon"
}
</script>

<style scoped>

</style>
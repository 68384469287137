<template>
  <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.2727 16.2634C21.3898 18.9169 19.2483 21.1608 16.4897 21.2736C16.2866 21.2821 6.38109 21.2621 6.38109 21.2621C3.63592 21.4705 1.23333 19.4979 1.0168 16.8544C1.00049 16.6574 1.00494 6.02903 1.00494 6.02903C0.883328 3.37266 3.02192 1.12309 5.78193 1.00605C5.98808 0.996057 15.8802 1.01461 15.8802 1.01461C18.6387 0.809069 21.0487 2.796 21.2623 5.45237C21.2771 5.64364 21.2727 16.2634 21.2727 16.2634Z" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.2773 8.21265L26.0468 4.30928C27.2287 3.34177 29.0015 4.18472 29.0001 5.70986L28.9827 16.354C28.9812 17.8791 27.207 18.7148 26.028 17.7473L21.2773 13.8439" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "MainVideoIcon"
}
</script>

<style scoped>

</style>
<template>
  <div>
    <Logo style="padding-top: 64px; text-align: center; display: flex; justify-content: center;"></Logo>
    <div style="display: flex; flex-direction: column; gap: 12px; justify-content: center; height: 548px;">
      <div v-if="type !=='INQUIRY'" style="display: flex; justify-content: center; flex-direction:column;">
        <p style="font-size: 40px; color: var(--clr-primary); padding-left: 42px; padding-right: 42px; padding-bottom: 24px; text-align: center;">{{i18n.global.t("thank_you")}}</p>
        <p style="font-size: 18px; color: black; padding-left: 24px; padding-right: 24px; padding-bottom: 12px;text-align: center;">{{i18n.global.t("thank_you_info")}}</p>
        <p style="font-size: 18px; color: black; padding-left: 24px; padding-right: 24px; padding-bottom: 24px; text-align: center;">{{i18n.global.t("thank_you_label")}}</p>
        <p style="font-size: 20px; color: #a1a1a1; padding-left: 70px; padding-right: 70px; text-align: center;">{{i18n.global.t("best_regards")}}</p>
        <p style="font-size: 20px; color: #a1a1a1; padding-left: 70px; padding-right: 70px; text-align: center;">{{company_name}}</p>
      </div>
      <div v-else style="display: flex; justify-content: center; flex-direction:column;">
        <p style="font-size: 40px; color: var(--clr-primary); padding-left: 42px; padding-right: 42px; padding-bottom: 24px; text-align: center;">{{i18n.global.t("thank_you_inquiry")}}</p>
        <p style="font-size: 18px; color: black; padding-left: 24px; padding-right: 24px; padding-bottom: 24px; text-align: center;">{{i18n.global.t("thank_you_inquiry_label")}}</p>
        <p style="font-size: 20px; color: #a1a1a1; padding-left: 70px; padding-right: 70px; text-align: center;">{{i18n.global.t("best_regards")}}</p>
        <p style="font-size: 20px; color: #a1a1a1; padding-left: 70px; padding-right: 70px; text-align: center;">{{company_name}}</p>
      </div>
    </div>
    <button class="widget-book-submit" @click="setPage('main')">{{i18n.global.t("go_back")}}</button>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import FooterBlock from "../Footer";
import Logo from "./Logo";
import i18n from "../../locale";
import store from "@/store";

export default {
  name: "ThankYou",
  components: {Logo, FooterBlock},
  data(){
    return{
      i18n:i18n
    }
  },
  computed:{
    type(){
      return store.getters.type;
    },
    company_name(){
      return store.getters.company_name;
    }
  },
  methods:{
    setPage(page){
      store.dispatch('setPage',page)
    }
  }
}
</script>

<style scoped>

</style>
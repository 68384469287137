<template>
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.83029 15.8616C2.39963 11.395 4.07163 6.28965 8.76096 4.77898C11.2276 3.98298 13.9503 4.45231 16.001 5.99498C17.941 4.49498 20.7636 3.98831 23.2276 4.77898C27.917 6.28965 29.5996 11.395 28.1703 15.8616C25.9436 22.9416 16.001 28.395 16.001 28.395C16.001 28.395 6.13163 23.0243 3.83029 15.8616Z" stroke="var(--clr-primary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "MainHeartIcon"
}
</script>

<style scoped>

</style>
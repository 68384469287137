import Vue from 'vue';

import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './locale'

Vue.config.productionTip = false

import wrap from '@vue/web-component-wrapper';
import VueWebComponent from './components/VueWebComponent';

import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
    componentPrefix: 'vc',
});
Vue.use(VueAxios, axios)
Vue.use(i18n);

const CustomElement = wrap(Vue, VueWebComponent);
window.customElements.define('virtual-seller', CustomElement);
import { render, staticRenderFns } from "./VueWebComponent.vue?vue&type=template&id=1c48898a&"
import script from "./VueWebComponent.vue?vue&type=script&lang=js&"
export * from "./VueWebComponent.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./VueWebComponent.vue?vue&type=style&index=0&id=1c48898a&prod&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports
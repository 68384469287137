<template>
  <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.8333 4.08337H3.83333C2.26853 4.08337 1 5.3519 1 6.91671V23.9167C1 25.4815 2.26853 26.75 3.83333 26.75H20.8333C22.3981 26.75 23.6667 25.4815 23.6667 23.9167V6.91671C23.6667 5.3519 22.3981 4.08337 20.8333 4.08337Z" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 1.25V6.91667" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66699 1.25V6.91667" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 12.5834H23.6667" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.5 19.6666H15.1667" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.3335 16.8334V22.5" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "MainCalendarIcon"
}
</script>

<style scoped>

</style>
<template>
  <svg width="49" height="27" viewBox="0 0 49 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.304 5.57886C16.9434 5.82373 17.451 6.20386 17.827 6.71802C18.2023 7.23267 18.3904 7.81738 18.3904 8.47363C18.3904 9.42676 18.0092 10.209 17.2472 10.8198C16.485 11.4299 15.5072 11.7354 14.313 11.7354C13.54 11.7354 12.7983 11.5942 12.0868 11.3113C11.3752 11.0291 10.7739 10.6345 10.2837 10.1284L11.163 9.01733C11.5365 9.42114 11.9907 9.73462 12.5264 9.9563C13.0619 10.1775 13.6229 10.2881 14.2096 10.2881C14.9768 10.2881 15.6084 10.0979 16.1041 9.71606C16.5997 9.33496 16.8473 8.85449 16.8473 8.27344C16.8473 7.69263 16.6105 7.20898 16.1359 6.82227C15.6616 6.43628 15.0648 6.24268 14.3453 6.24268H12.6422V4.95557H14.3453C14.9739 4.95557 15.4965 4.7915 15.9122 4.46387C16.3278 4.13623 16.5356 3.72681 16.5356 3.23657C16.5356 2.7356 16.3118 2.31885 15.8644 1.98535C15.4164 1.65186 14.8434 1.4856 14.1454 1.4856C13.6338 1.4856 13.1448 1.58276 12.678 1.77759C12.2117 1.97217 11.8267 2.23999 11.5228 2.5813L10.7075 1.51782C11.1282 1.0542 11.6616 0.691406 12.3066 0.430176C12.9512 0.168701 13.6259 0.0385742 14.3293 0.0385742C15.4214 0.0385742 16.3198 0.319824 17.0233 0.88208C17.7269 1.44482 18.0786 2.15698 18.0786 3.02075C18.0786 3.58569 17.9189 4.09668 17.5986 4.552C17.2791 5.00708 16.8473 5.3501 16.304 5.57886Z" fill="var(--clr-primary)"/>
    <path d="M24.2418 4.42798C25.2759 4.42798 26.1409 4.77026 26.8358 5.45532C27.5321 6.14038 27.8796 6.9834 27.8796 7.98535C27.8796 9.06274 27.4861 9.9563 26.7002 10.668C25.9143 11.3794 24.9216 11.7354 23.7223 11.7354C22.4432 11.7354 21.4183 11.3418 20.6483 10.5559C19.8783 9.77002 19.4932 8.69434 19.4932 7.32935C19.4932 6.46167 19.6594 5.62183 19.9929 4.81104C20.3257 4.00146 20.7891 3.27515 21.3838 2.63281C21.9781 1.99072 22.7028 1.44067 23.5582 0.982178C24.4138 0.523926 25.3451 0.209229 26.3525 0.0385742L26.6326 1.42969C25.3054 1.71753 24.1873 2.22754 23.2787 2.96094C22.3701 3.6936 21.7419 4.54712 21.3954 5.52271C21.7368 5.16577 22.1594 4.89404 22.6631 4.70801C23.1669 4.52148 23.6927 4.42798 24.2418 4.42798ZM23.7223 10.2881C24.474 10.2881 25.097 10.072 25.5928 9.63989C26.0892 9.2085 26.3367 8.66284 26.3367 8.00146C26.3367 7.3728 26.0983 6.84888 25.6212 6.43042C25.1442 6.01221 24.5427 5.80298 23.8182 5.80298C23.4771 5.80298 23.1464 5.84595 22.8267 5.93066C22.5068 6.01587 22.2111 6.14136 21.9396 6.30664C21.6676 6.47168 21.449 6.69458 21.2843 6.97437C21.1185 7.25366 21.036 7.56714 21.036 7.91357C21.036 8.60669 21.2825 9.17554 21.7754 9.62036C22.2684 10.0654 22.9176 10.2881 23.7223 10.2881Z" fill="var(--clr-primary)"/>
    <path d="M33.4202 11.7354C32.1357 11.7354 31.0896 11.1912 30.2826 10.1045C29.4752 9.01733 29.0713 7.61011 29.0713 5.88306C29.0713 4.15601 29.4752 2.75 30.2826 1.66504C31.0896 0.581055 32.1357 0.0385742 33.4202 0.0385742C34.2631 0.0385742 35.0125 0.283936 35.6708 0.77417C36.3296 1.2644 36.8418 1.95459 37.2102 2.84448C37.5782 3.73535 37.761 4.7478 37.761 5.88306C37.761 7.01831 37.5782 8.03076 37.2102 8.92114C36.8418 9.81104 36.3296 10.5027 35.6708 10.9956C35.0125 11.4885 34.2631 11.7354 33.4202 11.7354ZM33.4202 10.2881C34.2408 10.2881 34.9143 9.87939 35.4385 9.06079C35.9644 8.24243 36.2262 7.18359 36.2262 5.88306C36.2262 4.58252 35.9644 3.52441 35.4385 2.70898C34.9143 1.89355 34.2408 1.4856 33.4202 1.4856C32.5895 1.4856 31.9114 1.89355 31.3894 2.70898C30.8677 3.52441 30.6062 4.58252 30.6062 5.88306C30.6062 7.18359 30.8688 8.24243 31.3934 9.06079C31.9182 9.87939 32.594 10.2881 33.4202 10.2881Z" fill="var(--clr-primary)"/>
    <path d="M47.9556 13.2352C47.3877 10.5292 44.4403 9.1261 42.3862 8.26453C42.3467 8.24792 42.3051 8.2345 42.2649 8.2179L42.3187 9.5929C48.9437 12.4227 45.2122 15.5179 45.027 15.6959C44.9912 15.7306 44.8914 15.8182 44.8594 15.8475C44.7704 15.921 44.6805 15.9945 44.5892 16.0636C43.5006 16.8929 42.4283 17.3864 41.2416 17.8451C37.7159 19.2064 33.9652 19.8339 30.2633 20.1708C30.115 20.1842 30.0417 20.194 29.8934 20.2067L29.8174 22.5553C31.2155 22.4369 32.6102 22.2828 33.9993 22.0697C37.5372 21.5267 41.1993 20.7198 44.4165 18.9003C46.2837 17.8441 48.52 15.923 47.9556 13.2352Z" fill="var(--clr-primary)"/>
    <path d="M22.6821 16.8849C22.6231 16.8202 22.5446 16.7845 22.4611 16.7845H21.4895H20.7285H20.4718C20.3451 16.7845 20.2316 16.868 20.1834 16.9969C20.1351 17.1254 20.1613 17.2733 20.251 17.3722L20.7324 17.9032L21.4928 18.7423L23.0182 20.4249L22.7058 20.431C21.0613 20.403 19.4183 20.3202 17.7813 20.1708C14.0792 19.8339 10.3283 19.2064 6.80299 17.8451C5.6161 17.3864 4.54408 16.8929 3.45533 16.0636C3.3639 15.9945 3.27406 15.921 3.18494 15.8475C3.15321 15.8182 3.05372 15.7306 3.01759 15.6959C2.83228 15.5179 -0.899284 12.4227 5.72559 9.5929L5.77955 8.2179C5.73927 8.2345 5.69837 8.24792 5.65809 8.26453C3.60401 9.1261 0.656746 10.5292 0.0888749 13.2352C-0.475578 15.923 1.76075 17.8441 3.62794 18.9003C6.84486 20.7198 10.5072 21.5267 14.0457 22.0697C16.9147 22.5094 19.8091 22.7308 22.7058 22.7772L22.9972 22.7802C22.9972 22.7802 21.9597 23.9252 21.4928 24.4396L20.7324 25.2787L20.251 25.8099C20.1613 25.9086 20.1351 26.0565 20.1834 26.1847C20.2316 26.3138 20.3451 26.3973 20.4718 26.3973H20.7285H21.4895H22.4611C22.5446 26.3973 22.6231 26.3617 22.6821 26.2968L26.7272 21.8346C26.7861 21.7699 26.8185 21.6832 26.8185 21.5914V21.5909C26.8185 21.4991 26.7861 21.4127 26.7272 21.3483L22.6821 16.8849Z" fill="var(--clr-primary)"/>
  </svg>

</template>

<script>
export default {
  name: "Main360Icon"
}
</script>

<style scoped>

</style>
<template>
  <div class="widget" ref="widget" >
    <transition name="fade" mode="out-in">

      <div class="content" v-show="show_content" :class="[is_image ? 'widget-container' :'']">
        <main-page v-if="active_page === 'main'" :is_image="is_image"></main-page>
        <financing-page v-if="active_page === 'financing'"></financing-page>
        <form-page v-if="active_page === 'form'"></form-page>
        <thank-you-page v-if="active_page === 'thank_you'"></thank-you-page>
        <book-page v-if="active_page === 'book'"></book-page>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
    <div class="control" v-show="show_control">
      <div class="widget-book-submit-container" v-show="show_mobile">
        <button class="widget-book-submit" v-if="show_content" @click="handleContent()" style="width: 343px">Close</button>
        <button class="widget-book-submit" v-else @click="handleContent()" style="width: 343px">I’m interested</button>
      </div>
      <div v-show="show_tablet" style="float: right; cursor: pointer;" @click="handleContent">
          <peter></peter>
      </div>
    </div>
    </transition>
  </div>
</template>
<script>
import store from "../store";
import BookPage from "@/pages/BookPage";
import MainPage from "@/pages/MainPage.vue";
import FinancingPage from "@/pages/FinancingPage.vue";
import ThankYouPage from "@/pages/ThankYouPage.vue";
import Peter from "./svg/Peter";
import FormPage from "@/pages/FormPage.vue";
import i18n from "../locale";

export default {
  props: ['offer','portal','color','size','image','refresh','lang','logo','max_height','company_name'],
  components: {FormPage, Peter, BookPage,ThankYouPage, FinancingPage, MainPage},
  data(){
    return{
      show_content:true,
      show_control:false,
      show_mobile:false,
      show_tablet:false,
      i18n: i18n,
    }
  },
  mounted() {
    if (this.color === undefined){
      this.$refs.widget.style.setProperty('--clr-primary', this.hexToRbga('#FA5D14',1));
      this.$refs.widget.style.setProperty('--clr-primary-light', this.hexToRbga('#FA5D14',0.1));
    } else {
      this.$refs.widget.style.setProperty('--clr-primary', this.hexToRbga(this.color,1));
      this.$refs.widget.style.setProperty('--clr-primary-light', this.hexToRbga(this.color,0.1));
    }
    if (this.max_height === undefined){
      this.$refs.widget.style.setProperty('--max-height','100%');
    } else {
      this.$refs.widget.style.setProperty('--max-height',`${this.max_height}px`);
    }
    if (this.logo){
      store.dispatch('setLogo',this.logo);
    }

    if (this.refresh !== 'false'){
      let offer_data = {
        id:this.offer ?? 12679,
        portal:this.portal ?? 69,
      }
      store.dispatch('setOffer',offer_data);
    }

    if (this.size === 'mobile'){
      this.show_content = false;
      this.show_control = true;
      this.show_mobile = true;
    }
    if (this.size === 'tablet'){
      this.show_content = false;
      this.show_control = true;
      this.show_tablet = true;
    }

    if (this.company_name !== undefined){
      store.dispatch('setCompanyName',this.company_name);
    }

    i18n.global.locale = this.lang ?? i18n.global.fallbackLocale
    // i18n.global.locale = 'en'
  },
  methods:{
    up(){
      store.dispatch('count')
    },
    setPage(page){
      store.dispatch('setPage',page)
    },
    handleContent(){
      this.show_content = !this.show_content;
    },
    hexToRbga(hex,opacity){
      var c;
      if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(', ') + ', ' + opacity +')';
      }
      throw new Error('Bad Hex');
    }
  },
  computed:{
    active_page(){
      return store.getters.active_page;
    },
    is_image(){
      return this.image !== 'false';
    },
  },
  watch: { // It listens to the change in prop name
    offer: function (newVal, oldVal) {
      console.log('n:',newVal,'o:',oldVal)
      let offer_data = {
        id:newVal,
        portal:this.portal,
      }
      store.dispatch('setOffer',offer_data);
    },
  },
}
</script>
<style>
@import "../assets/scss/main.scss";
</style>
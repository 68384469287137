<template>
  <svg width="32" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.8813 11.1132C26.9457 10.8232 28.535 9.05333 28.5395 6.90906C28.5395 4.79587 26.999 3.04376 24.979 2.71228" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.605 16.076C29.6042 16.375 30.9997 17.0749 30.9997 18.5178C30.9997 19.5107 30.3427 20.1559 29.2801 20.5614" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0009 16.688C11.2447 16.688 7.18262 17.4087 7.18262 20.2869C7.18262 23.1637 11.2196 23.9051 16.0009 23.9051C20.757 23.9051 24.8177 23.1918 24.8177 20.3121C24.8177 17.4323 20.7822 16.688 16.0009 16.688Z" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0006 12.5801C19.1216 12.5801 21.6521 10.0511 21.6521 6.92867C21.6521 3.80772 19.1216 1.27722 16.0006 1.27722C12.8797 1.27722 10.3492 3.80772 10.3492 6.92867C10.3373 10.0393 12.8486 12.5698 15.9592 12.5801H16.0006Z" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.11906 11.1132C5.05323 10.8232 3.46538 9.05333 3.46094 6.90906C3.46094 4.79587 5.00143 3.04376 7.0214 2.71228" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.39472 16.076C2.39547 16.375 1 17.0749 1 18.5178C1 19.5107 1.65704 20.1559 2.71956 20.5614" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "MainUserIcon"
}
</script>

<style scoped>

</style>
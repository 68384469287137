<template>
  <div class="main-menu-content">
    <a class="main-menu-button" :href="vc360" target="_blank"><span><main-heart-icon></main-heart-icon></span>{{i18n.global.t("menu.like")}}</a>
    <a class="main-menu-button" :href="vc360" target="_blank"><span><main360-icon></main360-icon></span>{{i18n.global.t("menu.gallery")}}</a>
    <a class="main-menu-button" :href="vc360" target="_blank"><span><main-cert-icon></main-cert-icon></span>{{i18n.global.t("menu.cert")}}</a>
    <a class="main-menu-button" :href="vc360" target="_blank"><span><main-share-icon></main-share-icon></span>{{i18n.global.t("menu.share")}}</a>
    <a class="main-menu-button" :href="vc360" target="_blank"><span><main-profile-icon></main-profile-icon></span>{{i18n.global.t("menu.sign_in")}}</a>
  </div>
</template>

<script>
import store from '@/store'
import MainHeartIcon from "./svg/main/MainHeartIcon";
import Main360Icon from "./svg/main/Main360Icon";
import MainCertIcon from "./svg/main/MainCertIcon";
import MainShareIcon from "./svg/main/MainShareIcon";
import MainProfileIcon from "./svg/main/MainProfileIcon";
import i18n from "../locale";

export default {
  name: "MainMenuComponent",
  components: {MainProfileIcon, MainShareIcon, MainCertIcon, Main360Icon, MainHeartIcon},
  data(){
    return{
      i18n:i18n
    }
  },
  computed:{
    vc360(){
      return store.getters.car.player360Url
    }
  }
}
</script>

<style scoped>

</style>
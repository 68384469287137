<template>
<div class="thank_you" style="cursor:pointer;">
  <thank-you></thank-you>
</div>
</template>

<script>
import store from "@/store";
import ThankYou from "../components/svg/ThankYou";
export default {
  name: "ThankYouPage",
  components: {ThankYou},
  data() {
    return {
      thankYou_svg: require('../assets/thankyou.svg'),
    }
  },
  methods:{
    setPage(page){
      store.dispatch('setPage',page)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <svg width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2" cy="2.5" r="2" fill="#E0E0E0"/>
  </svg>

</template>

<script>
export default {
  name: "MainDotSeparator"
}
</script>

<style scoped>

</style>
<template>
  <div class="main-container">
    <div class="main-wrapper">
      <div class="main-photo" v-show="is_image">
<!--        <img :src="car.imageUrl" style="max-width: 375px; border-top-left-radius: 12px; border-top-right-radius: 12px;">-->
        <iframe :src="car.player360Url" width="100%" height="215px" allowfullscreen style="max-width: 375px; border-top-left-radius: 12px; border-top-right-radius: 12px; border: 1px transparent;"></iframe>
      </div>
      <div class="main-content-wrapper">
        <div class="main-content">
          <div class="main-content-title">{{ car.name }}</div>
          <div class="main-content-subtitle">{{ car.model }}</div>
          <div class="main-content-car_desc">{{ car.year }} <span><main-dot-separator></main-dot-separator></span> {{ car.milage }} <span><main-dot-separator></main-dot-separator></span> {{ car.fuel_type }} <span><main-dot-separator></main-dot-separator></span> {{ car.gearbox }}
          </div>
          <div class="main-content-amounts">
            <main-amounts-component></main-amounts-component>
          </div>
          <div>
            <main-menu-component></main-menu-component>
            <div>
              <div class="main-content-options">
                <button class="button_white button_height_40" @click="setPage('book','INQUIRY')">
                  <span class="main-content-options-span"><main-calendar-icon></main-calendar-icon></span>{{ i18n.global.t("main.inquiry")}}
                </button>
                <button class="button_white button_height_40" @click="setPage('book','ONLINE')">
                  <span class="main-content-options-span"><main-video-icon></main-video-icon></span>{{ i18n.global.t("main.video_call")}}
                </button>
                <button class="button_white button_height_40" @click="setPage('book','MEETING')">
                  <span class="main-content-options-span"><main-user-icon></main-user-icon></span>{{ i18n.global.t("main.meeting")}}
                </button>
                <button class="button_white button_height_40" @click="setPage('book','TEST_DRIVE')">
                  <span  class="main-content-options-span"><main-car-icon></main-car-icon></span>{{ i18n.global.t("main.test_drive")}}
                </button>
                <button class="button_primary">
                  <span><main-call-icon></main-call-icon></span>{{ phone }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import MainMenuComponent from "@/components/MainMenuComponent.vue";
import MainAmountsComponent from "@/components/MainAmountsComponent.vue";
import MainCalendarIcon from "../components/svg/main/MainCalendarIcon";
import MainVideoIcon from "../components/svg/main/MainVideoIcon";
import MainUserIcon from "../components/svg/main/MainUserIcon";
import MainCarIcon from "../components/svg/main/MainCarIcon";
import MainCallIcon from "../components/svg/main/MainCallIcon";
import MainDotSeparator from "../components/svg/main/MainDotSeparator";
import i18n from "../locale";

export default {
  name: "MainPage",
  props: ['is_image'],
  components: {
    MainDotSeparator,
    MainCallIcon,
    MainCarIcon, MainUserIcon, MainVideoIcon, MainCalendarIcon, MainAmountsComponent, MainMenuComponent},
  data() {
    return {
      audi: require('../assets/AudiA7.png'),
      dot: require('../assets/dot.svg'),
      phone_svg: require('../assets/Call.svg'),
      car_svg: require('../assets/Car.svg'),
      calendar_svg: require('../assets/calendar.svg'),
      user_svg: require('../assets/User.svg'),
      video_scg: require('../assets/Video.svg'),
      i18n: i18n,
    }
  },
  computed: {
    car() {
      return store.getters.car;
    },
    phone() {
      return store.getters.phone;
    },
    // is_image(){
    //   return store.getters.is_image;
    // }
  },
  methods:{
    setPage(page, type){
      store.dispatch('setType',type)
      store.dispatch('setPage',page)
    }
  }
}

</script>
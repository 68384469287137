<template>
  <div>
    <back-component></back-component>
    <p class="widget-salesman-label">{{ typeLabel }}</p>
    <book-salesman-container></book-salesman-container>
    <svg width="375" height="1" viewBox="0 0 375 1" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H375V1H0V0Z" fill="#E0E0E0"/>
    </svg>
    <date-picker :locale="i18n.global.locale" v-show="type !=='INQUIRY'" title-position="left" v-model="date" color="orange" @input="setDate"></date-picker>
    <svg v-show="type !=='INQUIRY'" width="375" height="1" viewBox="0 0 375 1" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H375V1H0V0Z" fill="#E0E0E0"/>
    </svg>
    <book-time-container v-show="type !=='INQUIRY'" ></book-time-container>
    <svg v-show="type !=='INQUIRY'" width="375" height="1" viewBox="0 0 375 1" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H375V1H0V0Z" fill="#E0E0E0"/>
    </svg>
    <div class="widget-book-note">
      <notes-icon></notes-icon>
      <textarea v-if="type !=='INQUIRY'" class="widget-book-note-area" style="height: 75px" :placeholder="i18n.global.t('book.note_placeholder')" @input="setNotes" v-model="notes"></textarea>
      <textarea v-else class="widget-book-note-area" style="height: 437px;font-size: 16px;line-height: 24px;padding-top: 0px;" :placeholder="i18n.global.t('book.inquiry_placeholder')" @input="setNotes" v-model="notes"></textarea>
    </div>
    <div class="widget-book-submit-container">
      <button v-if="validate" class="widget-book-submit" @click="setPage('form')">{{ typeLabel }}</button>
      <button v-else class="widget-book-submit is-disabled" disabled>{{ typeLabel }}</button>
    </div>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import {  DatePicker } from 'v-calendar';
import store from "@/store";

import BackComponent from "@/components/BackComponent";
import BookSalesmanContainer from "@/components/book/BookSalesmanContainer";
import BookTimeContainer from "@/components/book/BookTimeContainer";
import NotesIcon from "@/components/svg/NotesIcon";
import i18n from "../locale";
import FooterBlock from "../components/Footer";

export default {
  name: "BookPage",
  components: {
    FooterBlock,
    NotesIcon,
    BookTimeContainer,
    BookSalesmanContainer,
    BackComponent,
    DatePicker
  },
  data(){
    return{
      user: null,
      time: null,
      notes: null,
      i18n:i18n,
    }
  },
  methods: {
    setDate(date){
      store.dispatch('setDate',date);
    },
    setPage(page){
      store.dispatch('setPage',page);
    },
    setNotes(){
      store.dispatch('setNotes',this.notes);
    }
  },
  computed:{
    date(){
      return store.getters.book.date;
    },
    type(){
      return store.getters.type;
    },
    typeLabel(){
      return i18n.global.t(`book.type.${store.getters.type}`)
    },
    validate(){
      if (store.getters.type  !== 'INQUIRY'){
        if (store.getters?.book?.date && store.getters?.book?.time && store.getters?.book?.person){
          let now = new Date();
          let book_date = new Date(new Date(store.getters.book.date).toISOString().split('T')[0] + 'T' + (store.getters.book.time ?? '00:00'))
          if(book_date - now > 3659000 ){
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (store.getters.book?.notes?.length > 5 && store.getters?.book?.person){
          return true ;
        } else {
          return false;
        }
      }
    }
  }
}
</script>

<style>
@import "../assets/calendar.css";
</style>
<template>
  <svg width="23" height="31" viewBox="0 0 23 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6898 29.3973C6.26552 29.3973 1.6333 28.5532 1.6333 25.1728C1.6333 21.7923 6.23614 18.6716 11.6898 18.6716C17.1141 18.6716 21.7463 21.762 21.7463 25.1425C21.7463 28.5216 17.1435 29.3973 11.6898 29.3973Z" stroke="var(--clr-primary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6781 13.9872C15.2377 13.9872 18.1229 11.1021 18.1229 7.54242C18.1229 3.98277 15.2377 1.09631 11.6781 1.09631C8.11843 1.09631 5.23197 3.98277 5.23197 7.54242C5.21995 11.0901 8.08504 13.9752 11.6327 13.9872C11.6487 13.9872 11.6634 13.9872 11.6781 13.9872Z" stroke="var(--clr-primary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "MainProfileIcon"
}
</script>

<style scoped>

</style>
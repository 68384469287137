<template>
  <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33317 24.7385C10.3582 24.7385 11.9998 23.0969 11.9998 21.0718C11.9998 19.0468 10.3582 17.4052 8.33317 17.4052C6.30813 17.4052 4.6665 19.0468 4.6665 21.0718C4.6665 23.0969 6.30813 24.7385 8.33317 24.7385Z" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.6667 24.7385C28.6917 24.7385 30.3333 23.0969 30.3333 21.0718C30.3333 19.0468 28.6917 17.4052 26.6667 17.4052C24.6416 17.4052 23 19.0468 23 21.0718C23 23.0969 24.6416 24.7385 26.6667 24.7385Z" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.66667 21.0718H1V10.0718M1 10.0718L4.66667 0.905151H21.1667L28.5 10.0718M1 10.0718H28.5M28.5 10.0718H30.3333C31.3058 10.0718 32.2384 10.4581 32.9261 11.1458C33.6137 11.8334 34 12.766 34 13.7385V21.0718H30.3333M23 21.0718H12M17.5 10.0718V0.905151" stroke="var(--clr-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "MainCarIcon"
}
</script>

<style scoped>

</style>
<template>
<div class="main-amounts">
  <div class="main-amounts-left">{{ car.installment_price }}</div>
  <div class="main-amounts-right">
    <div class="main-amounts-right-top">{{ car.gross_price }} zł</div>
    <div class="main-amounts-right-bottom">{{ car.net_price }} zł net</div>
  </div>
</div>
</template>

<script>
import store from "@/store";

export default {
  name: "MainAmountsComponent",
  computed: {
    car() {
      return store.getters.car;
    },
  }
}
</script>

<style scoped>

</style>
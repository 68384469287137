// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-2!../../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-3!./_book.scss");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-2!../../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-3!./_form.scss");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-2!../../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-3!./_vue-horizontal-scroll.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
exports.push([module.id, ".widget{--clr-primary:#fa5d14;max-width:375px;margin:0 auto;font-size:62.5%;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;-webkit-text-size-adjust:100%;-moz-text-size-adjust:100%;text-size-adjust:100%;scroll-behavior:smooth;font-size:1.8rem;font-family:Inter,sans-serif!important}.widget p{margin-top:0;margin-bottom:0}.fade-enter-active,.fade-leave-active{transition:opacity .3s ease}.widget-container{background:#fff;box-shadow:0 4px 12px hsla(0,0%,79.6%,.15);border-radius:12px;max-height:var(--max-height);overflow-y:auto}.content{margin-bottom:20px}", ""]);
// Exports
module.exports = exports;

import { render, staticRenderFns } from "./MainCalendarIcon.vue?vue&type=template&id=20c5e9e7&scoped=true&"
import script from "./MainCalendarIcon.vue?vue&type=script&lang=js&"
export * from "./MainCalendarIcon.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "20c5e9e7",
  null
  ,true
)

export default component.exports
<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5729 1.51172C2.9149 1.51172 1.5399 2.85372 1.5009 4.50872V15.3397C1.4719 16.9867 2.7789 18.3387 4.4139 18.3667L4.5609 18.3657H12.5729C14.2149 18.3477 15.5539 16.9907 15.5519 15.3407V6.34172L10.9179 1.51172H4.5849H4.5729ZM4.3999 19.8667C1.9269 19.8237 -0.0410959 17.7867 0.000904076 15.3267V4.49072C0.0579041 2.00972 2.1069 0.0117188 4.5699 0.0117188H4.5879H11.2369C11.4409 0.0117188 11.6359 0.0947188 11.7779 0.241719L16.8439 5.52072C16.9769 5.65972 17.0519 5.84672 17.0519 6.03972V15.3397C17.0559 17.8087 15.0499 19.8397 12.5809 19.8657L4.3999 19.8667Z" fill="#A1A1A1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2976 6.98437H13.5436C11.7126 6.97937 10.2246 5.48737 10.2246 3.65937V0.750366C10.2246 0.336366 10.5606 0.000366211 10.9746 0.000366211C11.3886 0.000366211 11.7246 0.336366 11.7246 0.750366V3.65937C11.7246 4.66337 12.5416 5.48137 13.5456 5.48437H16.2976C16.7116 5.48437 17.0476 5.82037 17.0476 6.23437C17.0476 6.64837 16.7116 6.98437 16.2976 6.98437Z" fill="#A1A1A1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7935 11.6641H5.89453C5.48053 11.6641 5.14453 11.3281 5.14453 10.9141C5.14453 10.5001 5.48053 10.1641 5.89453 10.1641H10.7935C11.2075 10.1641 11.5435 10.5001 11.5435 10.9141C11.5435 11.3281 11.2075 11.6641 10.7935 11.6641Z" fill="#A1A1A1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.34448 14.1142C7.93048 14.1142 7.59448 13.7782 7.59448 13.3642V8.46423C7.59448 8.05023 7.93048 7.71423 8.34448 7.71423C8.75848 7.71423 9.09448 8.05023 9.09448 8.46423V13.3642C9.09448 13.7782 8.75848 14.1142 8.34448 14.1142Z" fill="#A1A1A1"/>
  </svg>

</template>

<script>
export default {
  name: "NotesIcon"
}
</script>

<style scoped>

</style>
<template>
  <div style="width: 1px; height: 26px;background-color: #E0E0E0;margin-top: 14px;"></div>
</template>

<script>
export default {
  name: "Separator"
}
</script>

<style scoped>

</style>
<template>
  <div class="widget-book-time" :class="[isActive ? 'active' : '']">
    <p class="widget-book-time-value">{{ time.label }}</p>
  </div>
</template>

<script>
import store from "../../store";

export default {
  name: "BookTime",
  props: ['time'],
  computed: {
    isActive() {
      if (this.time.value) {
        return this.time.value === store.getters.book.time;
      } else {
        return false;
      }
    }
  }
}
</script>

<style scoped>

</style>
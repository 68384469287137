<template>
  <div class="widget-salesman" @click="setPerson()">
    <img v-if="person.image" class="widget-salesman-image" :class="[isActive ? 'active' : '']" :src="person.image">
    <salesman-icon v-else class="widget-salesman-image" :class="[isActive ? 'active' : '']"></salesman-icon>
    <p class="widget-salesman-name">{{person?.name}}</p>
  </div>

</template>

<script>
import store from "../../store";
import SalesmanIcon from "../svg/SalesmanIcon";
export default {
  name: "BookSalesman",
  components: {SalesmanIcon},
  props: ['person'],
  computed:{
    isActive(){
      if (this.person?.id){
        return this.person.id === store.getters.book.person;
      } else {
        return false;
      }
    }
  },
  methods:{
    setPerson(){
      store.dispatch('setUser',this.person.id)
    }
  }
}
</script>

<style scoped>
.active{
  border: 2px solid var(--clr-primary);
}
</style>
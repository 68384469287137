import { render, staticRenderFns } from "./BackIcon.vue?vue&type=template&id=18395e59&scoped=true&"
import script from "./BackIcon.vue?vue&type=script&lang=js&"
export * from "./BackIcon.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "18395e59",
  null
  ,true
)

export default component.exports
<template>
  <div style="margin: 0 auto; padding: 12px;width: fit-content;">
    <span style="color: #A1A1A1; font-size: 12px;font-weight: 600;">by VC360</span>
  </div>
</template>

<script>
export default {
  name: "FooterBlock"
}
</script>

<style scoped>

</style>
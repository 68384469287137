<template>
  <div class="widget-back" @click="goBack">
    <back-icon></back-icon>
    <p class="widget-back--label">{{ i18n.global.t("back") }}</p>
  </div>
</template>

<script>
import BackIcon from "./svg/BackIcon";
import store from "../store";
import i18n from "../locale";

export default {
  name: "BackComponent",
  components: {BackIcon},
  props:{
    page:{
      default: 'main',
    }
  },
  data(){
    return{
      i18n:i18n
    }
  },
  methods:{
    goBack(){
      store.dispatch('setPage',this.page);
    }
  }
}
</script>

<style scoped>

</style>